<template>
  <div class="bx-flooding bx-flooding--footer">
    <footer class="bx-footer bx-typo--footer">
      <div class="bx-footer__wrapper">
        <div class="bx-grid">
          <NewsletterSubscription usage="footer"
                                  headline="Newsletter-Anmeldung" />
          <SeoHeadline />
          <FooterAboTeaser :cover-url="coverUrl" />
          <SocialLinks menu-type="footer" />
        </div>
      </div>
      <div class="bx-footer__menu">
        <div class="bx-grid">
          <MenuList :menu="footerData.menu"
                    menu-type="footer" />
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import MenuList from '../../../components/menu/MenuList.vue'
import SeoHeadline from '../../../components/page-footer/SeoHeadline.vue'
import NewsletterSubscription from '../../../components/shared/NewsletterSubscription.vue'
import SocialLinks from '../../../components/shared/SocialLinks.vue'
import FooterAboTeaser from './FooterAboTeaser.vue'
export default {
  components: {
    MenuList,
    SeoHeadline,
    SocialLinks,
    NewsletterSubscription,
    FooterAboTeaser
  },
  props: {
    footerData: {
      type: Object,
      default: () => { return {} }
    },
    brand: {
      type: String,
      default: null
    }
  },
  computed: {
    coverUrl () {
      return `${process.env.COVER_URL}${this.brand.toLowerCase()}.jpg`
    }
  },
  mounted () {
    if (!import.meta.env.SSR) {
      const newsletterTeaser = document.getElementsByClassName('bx-teaser-container--newsletter')
      if (newsletterTeaser.length > 0) {
        for (const teaser of newsletterTeaser) {
          teaser.style.backgroundImage = 'url(/images/intouch/newsletter-teaser_bg.png)'
        }
      }
    }
  }
}
</script>
